import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.addEventListeners();
  }

  addEventListeners() {
    const orgTypeSelect = document.querySelector<HTMLSelectElement>('.shared--organization-types-component select');
    const relationshipSelect = document.querySelector<HTMLSelectElement>('.shared--relationship-with-org');

    if (orgTypeSelect && relationshipSelect) {
      orgTypeSelect.addEventListener('change', () => {
        if (orgTypeSelect.value) {
          this.removeHiddenClass(relationshipSelect);
        }
      });
    }

    if (!orgTypeSelect.value) {
      relationshipSelect.classList.add('hidden');
    }
  }

  removeHiddenClass(element: HTMLElement) {
    element.classList.remove('hidden');
  }

}
