import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'bellIcon',
    'badgeCounter'
  ];

  declare bellIconTarget: HTMLElement;
  declare badgeCounterTarget: HTMLElement;

  public connect(): void {
    window.addEventListener('productfruits_ready', () => {
      window.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
        const unreadCount = data.count;

        if (unreadCount > 9) {
          this.badgeCounterTarget.style.visibility = 'visible';
          this.badgeCounterTarget.innerHTML = '9+'
        } else if (unreadCount == 0) {
          this.badgeCounterTarget.style.visibility = 'hidden';
        } else {
          this.badgeCounterTarget.style.visibility = 'visible';
          this.badgeCounterTarget.innerHTML = unreadCount
        }
      });

      window.productFruits.api.announcementsV2.attachNewsWidgetToElement(this.bellIconTarget);
    });
  }
}