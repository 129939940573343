import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['relationshipWithOrg', 'voterMessage'];

  declare relationshipWithOrgTarget: HTMLSelectElement;
  declare voterMessageTarget: HTMLElement;

  initialize() {
    this.relationshipWithOrgTarget.addEventListener('change', (e) => this.changeSelectTextColor(e.target));
    this.changeSelectTextColor(this.relationshipWithOrgTarget);
  }

  changeSelectTextColor(target: HTMLSelectElement) {
    if (target.value === '') {
      target.classList.add('placeholder');
    } else {
      target.classList.remove('placeholder');
    }

    if (target.value.toLowerCase().includes('voter')) {
      this.showVoterMessage();
    } else {
      this.hideVoterMessage();
    }
  }

  showVoterMessage() {
    this.voterMessageTarget.classList.remove('hidden');
  }

  hideVoterMessage() {
    this.voterMessageTarget.classList.add('hidden');
  }
}
