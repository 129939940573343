import { Controller } from 'stimulus'
import Nprogress from 'nprogress'

export default class extends Controller {
  static targets = ['sourceElectionId', 'submitButton', 'statusMessageOutput', 'incompatibleElectionsDiv']

  declare sourceElectionIdTarget: HTMLInputElement
  declare submitButtonTarget: HTMLButtonElement
  declare statusMessageOutputTarget: HTMLElement
  declare incompatibleElectionsDivTarget: HTMLElement

  connect() {
    this.verifyInProgressCopyVoters();
  }

  switchAllSelections(event) {
    const checked = event.target.checked
    let targetElections = this.#getAllCheckboxes()

    targetElections.forEach((checkbox) => { checkbox.checked = checked })
    this.changeSubmitButtonDisableAttribute(!checked)
  }

  switchCheckbox(_event) {
    let targetElections = this.#getAllCheckboxes()

    let someChecked = targetElections.some((checkbox) => { return checkbox.checked })
    this.changeSubmitButtonDisableAttribute(!someChecked)
  }

  submit(event) {
    event.preventDefault()
    let targetElectionsIds = this.getAllSelectedTargetElectionsIds()
    Rails.ajax({
      type: 'post',
      url: `/elections/${this.sourceElectionId}/voter_list/copy/copies`,
      data: new URLSearchParams(targetElectionsIds.map(id => ['target_election_ids[]', id])).toString(),
      success: (_response) => { this.initCopyVotersStatusCheck() }
    })
  }

  get sourceElectionId() {
    return this.sourceElectionIdTarget.value;
  }

  set processingStatusMessageClass(className) {
    return this.statusMessageOutputTarget.className = className
  }

  set processingStatusMessageText(text) {
    return this.statusMessageOutputTarget.textContent = text
  }

  #getAllCheckboxes() {
    return [...document.querySelectorAll('#copy-voter-list-page-component table tbody input[type="checkbox"]')]
  }

  changeSubmitButtonDisableAttribute(disabledValue) {
    this.submitButtonTarget.disabled = disabledValue
  }

  getAllSelectedTargetElectionsIds() {
    return this.#getAllCheckboxes().filter(checkbox => checkbox.checked ).map(checkebox => checkebox.value)
  }

  scheduleCopyVotersStatusCheck() {
    Rails.ajax({
      type: 'get',
      url: `/elections/${this.sourceElectionId}/voter_list/copy/copies/status`,
      success: (processingStatus) => {
        let processingDone = processingStatus && processingStatus.done || false
        if (processingDone) {
          this.#showProcessingStatusMessage(processingStatus)
          NProgress.done()
        } else {
          setTimeout(() => { this.scheduleCopyVotersStatusCheck() }, 500)
        }
      },
    })
  }

  #showProcessingStatusMessage(processingStatus) {
    if(processingStatus.error) {
      this.processingStatusMessageClass = 'inline_error'
      this.processingStatusMessageText = 'Copy failed.'
    } else {
      this.processingStatusMessageClass = 'inline_success'
      this.processingStatusMessageText = 'Copy completed!'
    }
  }

  verifyInProgressCopyVoters(){
    Rails.ajax({
      type: 'get',
      url: `/elections/${this.sourceElectionId}/voter_list/copy/copies/status`,
      success: (processingStatus) => {
        if (Object.entries(processingStatus).length === 0) return;
        if (processingStatus && !processingStatus.done) {
          this.initCopyVotersStatusCheck();
        }
      },
    })
  }

  initCopyVotersStatusCheck() {
    NProgress.configure({ parent: '#copy-voter-list-page-component' });
    NProgress.start();
    this.processingStatusMessageText = '';
    this.scheduleCopyVotersStatusCheck();
  }

  toggleIncompatibleElections(event: Event) {
    const wasHidden = this.incompatibleElectionsDivTarget.classList.toggle('hide')
    const eventTarget = event.target as HTMLAnchorElement
    eventTarget.textContent = wasHidden ? 'view details' : 'hide details'
  }
}

