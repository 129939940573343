import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['membershipTier'];

  declare membershipTierTarget: HTMLSelectElement;

  initialize() {
    this.membershipTierTarget.addEventListener('change', (e) => this.changeSelectTextColor(e.target));
    this.changeSelectTextColor(this.membershipTierTarget);
  }

  changeSelectTextColor(target: HTMLSelectElement) {
    if (target.value === '') {
      target.classList.add('placeholder');
    } else {
      target.classList.remove('placeholder');
    }
  }
}
