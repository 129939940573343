import { Controller } from 'stimulus';
import Quill from 'quill';

// Define and register DividerBlot
const BlockEmbed = Quill.import('blots/block/embed');

class DividerBlot extends BlockEmbed {}
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';

Quill.register(DividerBlot);

const toolbarOptions = [
  [{ 'size': [] }],
  ['bold', 'italic', 'underline', 'link', 'video', 'strike'],
  [
    { 'color': ['#000000', '#FFFFFF', '#E60000', '#FF9900', '#FFFF00', '#008A00', '#0066CC', '#9933FF'] },
    { 'background': ['#FFFFFF', '#FACCCC', '#FFEBCC', '#FFFFCC', '#CCE8CC', '#CCE0F5', '#EBD6FF', '#BBBBBB'] }
  ],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'align': [] }],
  ['divider', 'clean']
];

const emailNoticeTollbarOptions = [
  [{ 'size': [] }],
  ['bold', 'italic', 'underline', 'link', 'strike'],
  [
    { 'color': ['#000000', '#FFFFFF', '#E60000', '#FF9900', '#FFFF00', '#008A00', '#0066CC', '#9933FF'] },
    { 'background': ['#FFFFFF', '#FACCCC', '#FFEBCC', '#FFFFCC', '#CCE8CC', '#CCE0F5', '#EBD6FF', '#BBBBBB'] }
  ],
  [{ 'list': 'bullet' }], [{ 'align': [] }], ['divider', 'clean']
];

const minimalToolbarOptions = [];

const TOOLBAR_STYLES = {
  default: toolbarOptions,
  minimal: minimalToolbarOptions,
  email: emailNoticeTollbarOptions
};

export default class extends Controller {
  static targets = ['input', 'container', 'textContainer'];

  declare inputTarget: HTMLInputElement;
  declare containerTarget: HTMLElement;
  declare textContainerTarget: HTMLElement;

  private saved = true;
  private quill: Quill;

  public initialize(): void {
    this.initializeQuill();

    setInterval(this.updateInputValueWithQuillContent.bind(this), 2000);

    window.onbeforeunload = () => {
      if (!this.saved) {
        return 'There are unsaved changes. Are you sure you want to leave?';
      }
    };

    const parentComponent = window.EB.RichTextFields && window.EB.RichTextFields[this.containerTarget.dataset.id];
    parentComponent?.childrenInitCallback?.(this);
  }

  private initializeQuill(): void {
    this.quill = new Quill(this.textContainerTarget, this.getOptions());
    this.quill.on('text-change', (delta) => this.saved = false);

    if (this.containerTarget.dataset.disabled === 'true') {
      this.quill.enable(false); // Disable editor if disabled is truthy
    }

    this.addToolbarTooltips();
    this.registerVideoHandler();
  }

  private updateInputValueWithQuillContent(): void {
    if (this.saved) return;

    const content = this.getEditorContent();
    this.inputTarget.value = content;

    // TODO: using jquery here as a workaround
    // need go investigate why 'dispatchEvent(new Event('change'))' is not working for all forms
    $('.rich_text_textarea').trigger('change');
    this.saved = true;
  }

  private getEditorContent(): string {
    const editorContent = this.quill.getContents();
    if (!editorContent || editorContent.length() === 0) return '';

    return this.textContainerTarget.innerHTML;
  }

  private getOptions(): QuillOptionsStatic {
    const options: QuillOptionsStatic = {
      modules: {
        toolbar: TOOLBAR_STYLES[this.containerTarget.dataset.toolbarStyle] || TOOLBAR_STYLES.default
      },
      theme: 'snow'
    };
    return options;
  }

  private registerVideoHandler(): void {
    const toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('video', this.videoHandler.bind(this));
  }

  private videoHandler(): void {
    const range = this.quill.getSelection(true);
    const url = prompt('Enter the video URL:');

    if (url) {
      const adjustedUrl = this.adjustVideoUrl(url);
      this.quill.insertEmbed(range.index, 'video', adjustedUrl, Quill.sources.USER);
      this.saved = false;
    }
  }

  private adjustVideoUrl(url: string): string {
    // YouTube (full and minified URLs)
    const ytRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?(.*&)?v=|youtube\.com\/embed\/|youtu\.be\/)([\w\-]{11})(\?.*)?$/;
    const ytMatch = url.match(ytRegex);
    if (ytMatch && ytMatch[2]) {
      const videoId = ytMatch[2];
      const queryParams = ytMatch[3] || '';
      return `https://www.youtube.com/embed/${videoId}${queryParams}`;
    }

    // Vimeo
    const vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:.*\/)?(\d+)(?:$|\/|\?)/;
    const vimeoMatch = url.match(vimeoRegex);
    if (vimeoMatch && vimeoMatch[1]) {
      const videoId = vimeoMatch[1];
      return `https://player.vimeo.com/video/${videoId}`;
    }

    // Wistia
    const wistiaRegex = /^(https?:\/\/(?:[\w\.-]+\.wistia\.com))\/(medias|embed)\/([a-zA-Z0-9]+)/;
    const wistiaMatch = url.match(wistiaRegex);
    if (wistiaMatch && wistiaMatch[1] && wistiaMatch[3]) {
      const domain = wistiaMatch[1];
      const videoId = wistiaMatch[3];
      return `${domain}/embed/iframe/${videoId}`;
    }

    // Dailymotion
    const dmRegex = /^(?:https?:\/\/)?(?:www\.)?dailymotion\.com\/video\/([a-zA-Z0-9]+)/;
    const dmMatch = url.match(dmRegex);
    if (dmMatch && dmMatch[1]) {
      const videoId = dmMatch[1];
      return `https://www.dailymotion.com/embed/video/${videoId}`;
    }

    return url;
  }

  private addToolbarTooltips(): void {
    const tooltips = [
        { selector: '.ql-picker-label', title: 'Font Size' },
        { selector: 'button.ql-bold', title: 'Bold' },
        { selector: 'button.ql-italic', title: 'Italic' },
        { selector: 'button.ql-underline', title: 'Underline' },
        { selector: 'button.ql-link', title: 'Link' },
        { selector: 'button.ql-strike', title: 'Strike' },
        { selector: '.ql-color.ql-color-picker', title: 'Color' },
        { selector: '.ql-background.ql-color-picker', title: 'Background Color' },
        { selector: 'button.ql-list', title: 'Ordered List' },
        { selector: 'button.ql-list[value="bullet"]', title: 'Bullet List' },
        { selector: '.ql-align', title: 'Align' },
        { selector: 'button.ql-video', title: 'Video' }
    ];

    tooltips.forEach(({ selector, title }) => {
        const element = this.containerTarget.querySelector(selector);
        if (element) {
            element.setAttribute('title', title);
        }
    });
  }
}
