import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['orgTypeSelect', 'predefinedOtherSelect', 'text'];
  static values = {
    predefinedOtherOptions: Object,
    organizationTypeOther: String,
  };

  declare orgTypeSelectTarget: HTMLSelectElement;
  declare predefinedOtherSelectTarget: HTMLSelectElement;
  declare textTarget: HTMLInputElement;
  declare predefinedOtherOptionsValue: { [key: string]: string[] };
  declare organizationTypeOtherValue: string;

  initialize() {
    this.addEventListeners();
    this.applyInitialStyles();
    this.setInitialOrganizationTypeOtherValue();
  }

  addEventListeners() {
    this.orgTypeSelectTarget.addEventListener('change', this.handleOrgTypeChange.bind(this));
    this.predefinedOtherSelectTarget.addEventListener('change', this.handlePredefinedOtherChange.bind(this));
  }

  applyInitialStyles() {
    this.changeSelectTextColor(this.orgTypeSelectTarget);
    this.displayOrganizationTypeOther(this.orgTypeSelectTarget);
    this.changeSelectTextColor(this.predefinedOtherSelectTarget);
    this.displayOtherTextInput(this.predefinedOtherSelectTarget);
  }

  handleOrgTypeChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.displayOrganizationTypeOther(target);
    this.changeSelectTextColor(target);
  }

  handlePredefinedOtherChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.updateOtherTextInput(target);
    this.changeSelectTextColor(target);
    this.displayOtherTextInput(target);
  }

  changeSelectTextColor(target: HTMLSelectElement) {
    target.classList.toggle('placeholder', target.value === '');
  }

  displayOrganizationTypeOther(target: HTMLSelectElement) {
    const targetText = target.options[target.selectedIndex].text;
    const predefinedOptions = this.predefinedOtherOptionsValue[targetText];

    this.resetPredefinedSelectAndTextInput();

    if (predefinedOptions) {
      this.populatePredefinedOptions(predefinedOptions);
    } else {
      this.showTextInput();
    }
  }

  resetPredefinedSelectAndTextInput() {
    this.predefinedOtherSelectTarget.innerHTML = '';
    this.textTarget.value = '';
  }

  populatePredefinedOptions(options: string[]) {
    this.predefinedOtherSelectTarget.classList.remove('hidden');
    this.textTarget.classList.add('hidden');

    const placeholderOption = this.createOptionElement('', 'Select a type');
    placeholderOption.hidden = true;
    this.predefinedOtherSelectTarget.appendChild(placeholderOption);

    options.forEach(optionValue => {
      this.predefinedOtherSelectTarget.appendChild(this.createOptionElement(optionValue));
    });

    this.changeSelectTextColor(this.predefinedOtherSelectTarget);
  }

  showTextInput() {
    this.predefinedOtherSelectTarget.classList.add('hidden');
    this.textTarget.classList.remove('hidden');
  }

  createOptionElement(value: string, text: string = value) {
    const option = document.createElement('option');
    option.value = value;
    option.textContent = text;
    return option;
  }

  displayOtherTextInput(target: HTMLSelectElement) {
    const shouldShowTextInput = target.value.includes('Other');
    this.textTarget.classList.toggle('hidden', !shouldShowTextInput);
    if (shouldShowTextInput) this.textTarget.value = '';
  }

  updateOtherTextInput(target: HTMLSelectElement) {
    this.textTarget.value = target.value;
  }

  setInitialOrganizationTypeOtherValue() {
    if (!this.organizationTypeOtherValue){
      return
    }

    const optionExists = Array.from(this.predefinedOtherSelectTarget.options).some(
      option => option.value === this.organizationTypeOtherValue
    );

    if (optionExists) {
      this.predefinedOtherSelectTarget.value = this.organizationTypeOtherValue;
      this.textTarget.classList.add('hidden');
    } else {
      this.textTarget.value = this.organizationTypeOtherValue;
      this.textTarget.classList.remove('hidden');
      this.predefinedOtherSelectTarget.classList.add('hidden');
    }
  }
}
