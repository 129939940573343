import consumer from "./consumer";

consumer.subscriptions.create("Notifications::OrganizationChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    const notificationsContainer = document.getElementById('notification-container');
    notificationsContainer.innerHTML = data.html;
  }
});

