import {Controller} from 'stimulus';
import $ from 'jquery'

export default class extends Controller {
  static targets = ['form', 'deleteUserCheckbox'];

  private readonly SUBMIT_DELAY = 200;

  connect() {
    this.IndustryFieldSetup()
  }

  IndustryFieldSetup() {
    const selects = this.element.querySelectorAll("select");
    selects.forEach(select => {
      $(select).on('change', (event: Event) => {
        setTimeout(() => {
          this.formTarget.requestSubmit();
        }, this.SUBMIT_DELAY);
      });
    });
  }

  handleCheckboxChange(event: Event) {
    this.formTarget.requestSubmit();
  }

  handleDeleteUser(event: Event) {
    if (confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      this.formTarget.requestSubmit();
    }
  }
}
