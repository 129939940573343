import { Controller } from 'stimulus'
import $ from 'jquery'

const setToClipboard = async (blob: Blob) => {
  const data = [new ClipboardItem({ [blob.type]: blob })]
  await navigator.clipboard.write(data)
}

export default class extends Controller {
  static targets = ['qrImageContainer', 'qrButton', 'qrImageTag', 'copyQRCodeInsideModalButton']

  declare qrImageContainerTarget: HTMLElement
  declare qrButtonTarget: HTMLElement
  declare qrImageTagTarget: HTMLImageElement
  declare copyQRCodeInsideModalButtonTarget: HTMLImageElement

  declare originalQrButtonContent: string
  declare qrContainerElement: HTMLElement
  declare qrImageElement: HTMLImageElement
  declare copyQRInsideModal: HTMLElement

  initialize() {
    this.originalQrButtonContent = this.qrButtonTarget.innerHTML

    // need to store the reference to the element because it gets moved outside
    // of the component element when jQuery puts it in a modal. Next time you try
    // to access it with `this.qrImageTarget` the element is not found.
    this.qrContainerElement = this.qrImageContainerTarget
    this.qrImageElement = this.qrImageTagTarget
    this.copyQRInsideModal = this.copyQRCodeInsideModalButtonTarget
    this.copyQRInsideModal.addEventListener('click', this.copyQRCodeButtonClicked.bind(this))
  }

  qrButtonClicked(event: Event) {
    const target = event.target as HTMLElement
    if (target.innerText.match(/copy/i)) {
      this.copyQrImageToClipBoard(this.qrImageElement, target)
    } else {
      this.openModal()
    }
  }

  copyQRCodeButtonClicked(event: Event) {
    const target = event.target as HTMLElement
    this.copyQrImageToClipBoard(this.qrImageElement, target)
  }

  async copyQrImageToClipBoard(sourceImageTag: HTMLImageElement, sourceButton: HTMLElement) {
    const src = sourceImageTag.getAttribute('src')
    if (src) {
      const response = await fetch(src)
      const blob = await response.blob()
      await setToClipboard(blob)
      const originalContent = sourceButton.innerHTML
      sourceButton.innerHTML = 'Copied!'
      setTimeout(() => {
        sourceButton.innerHTML = originalContent
      }, 1000)
    }
  }

  openModal() {
    $(this.qrContainerElement).modal()
  }
}
